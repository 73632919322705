import { Heading, TimeUtil } from 'cuenect-web-core'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentContainer,
  InnerContainer,
  LineContainer,
  ShowRoom,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig } from '../config'
import { useVodContext } from './../utility'

const ShowroomPage = (params: PageParams) => {
  const {
    pageContext: { lang, program },
  } = params
  const { dispatch: openVod } = useVodContext()

  const { t } = useTranslation('showroom')
  const [loaded, setLoaded] = React.useState(false)

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  return (
    <PageFrame {...params} pageName="showroom">
      <ContentContainer docked fullwidth>
        {loaded && <ShowRoom />}
      </ContentContainer>
    </PageFrame>
  )
}

export default ShowroomPage
